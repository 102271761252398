/* eslint-disable camelcase */
import CheckBox from 'app/components/partials/checkbox';
import { clientEnv } from 'config/env';
import { CREATE_ACCOUNT_FORM_FIELDS } from 'config/buy-memberships-create-account';
import { createValidationDataObject } from 'app/utilities/form-validation';
import DatePicker from 'app/components/partials/date-picker';
import DayPassDiscountRedeem from '../day-pass-discount-redeem';
import { displayDateOfBirthFormat } from 'config/buy-memberships-passholder-details';
import { FRONTEND_ROUTES } from 'app/utilities/routes';
import GaDiscountRedeem from '../ga-discount-redeem';
import Icon from 'app/components/partials/icon';
import Input from 'app/components/partials/input';
import Loader from 'app/components/partials/loader';
import LoginForm from 'app/components/partials/portal/auth/login-form';
import Modal from 'app/components/partials/modal';
import OrderPricing from '../order-pricing';
import PropTypes from 'prop-types';
import RecaptchaPolicy from 'app/components/partials/recaptcha-policy';
import { userRegisterAction } from 'app/ducks/auth';
import { canRedeemDaypassDiscount, EVENT_BEGIN_CHECKOUT, EVENT_VIEW_CART, fireCartEvent } from 'app/utilities/buy-memberships';
import { clearPaymentStatusAction, setExtraDiscountAction, setPrimaryContactFieldValueAction, touchedPrimaryContactFieldAction } from 'app/ducks/buy-memberships';
import { GIFTER_DETAILS_STEP_INDEX, RECIPIENT_DETAILS_STEP_INDEX } from 'config/gift-memberships-steps-defs';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Review = ({ saveCosts, totalCosts, costsAfterDiscount, stepClickHandler, isGifting = false }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [redeemDiscountStatus, setRedeemDiscountStatus] = useState(null);
    const [status, setStatus] = useState(null);
    const [promoCodeInputValue, setPromoCodeInputValue] = useState('');
    const [helperMessage, setHelperMessage] = useState(null);
    const {
        currentStepIndex,
        passHolders,
        primaryContact,
        paymentStatus,
        gifter,
        giftReceiver,
        productsSelected,
        renewPassId,
        extraDiscount
    } = useSelector((state) => state.buyMemberships);
    const { isAuth, isLoading, user } = useSelector((state) => state.auth);

    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAuthUser = !isLoading && isAuth;
    const needsCreateAccount = !isAuthUser && !isGifting;

    const setFieldValue = (fieldName, value) => dispatch(setPrimaryContactFieldValueAction(fieldName, value));
    const touchedField = (fieldName) => dispatch(touchedPrimaryContactFieldAction(fieldName));

    const autoFillPrimaryContactInfo = () => {
        const qualifiedPrimaryContact = passHolders.find((pass) => pass.isPrimaryContactQualified);

        if (qualifiedPrimaryContact) {
            const { formFields } = qualifiedPrimaryContact;

            formFields.map(({ fieldName, value }) => setFieldValue(fieldName, value));
        }
    };

    const isAccountExists = status && status.error && status.error.code && status.error.code === 'account_exists';
    const isSfContactExists = status && status.error && status.error.code && status.error.code === 'sf_contact_exists';
    const needLoginWithoutRedirect = isAccountExists || isSfContactExists;
    const needResetPassword = status && status.error && status.error.code && status.error.code === 'need_reset_password';

    const modalVisible = Boolean(isAccountExists || isSfContactExists || needResetPassword) && !isAuthUser;

    const getFieldValue = (fieldName) => primaryContact[fieldName].value;
    const getFieldTouched = (fieldName) => primaryContact[fieldName].touched;

    const validateField = (fieldName, value, validator) => {
        if (fieldName === 'password_confirmation') {
            const passwordValue = getFieldValue('password');

            return validator(value, passwordValue);
        }

        return validator(value);
    };


    const checkIsFormValueInvalid = () => {
        if (!needsCreateAccount) return false;

        const invalid = CREATE_ACCOUNT_FORM_FIELDS.some(({ name, validator }) => {
            const value = getFieldValue(name);

            return !validateField(name, value, validator).valid;
        }, []);

        return invalid;
    };

    const resetStatus = () => setStatus();

    const submitForm = () => {
        // Push checkout event to data layer
        fireCartEvent(EVENT_BEGIN_CHECKOUT, productsSelected, costsAfterDiscount, renewPassId, isGifting);

        if (!needsCreateAccount) return stepClickHandler(currentStepIndex + 1);

        setIsSubmitting(true);
        resetStatus();
        const userData = CREATE_ACCOUNT_FORM_FIELDS.reduce((acc, { name }) => {
            let val = getFieldValue(name);
            if (typeof val === 'string') {
                val = val.trim();
            }
            acc[name] = val;

            return acc;
        }, {});

        // eslint-disable-next-line no-undef
        grecaptcha.ready(function() {
            // eslint-disable-next-line no-undef
            grecaptcha.execute(clientEnv.RECAPTCHA_KEY, { action: 'register_account' }).then(function(token) {
                // Pass recaptcha verify token for backend to validate
                userData.captchaToken = token;
                // Pass the discount code to backend
                if (extraDiscount && extraDiscount.ga && extraDiscount.ga.code) {
                    userData.ga_promo_code = extraDiscount.ga.code;
                }
                dispatch(userRegisterAction(userData, setIsSubmitting, setStatus));
            });
        });
    };

    const getGifterValue = (fieldName) => gifter[fieldName].value;
    const getGiftReceiverValue = (fieldName) => giftReceiver[fieldName].value;

    // Only for gifter and recipient details
    const renderContactDetails = ({ title, name, email, editStepIdx }) => {
        return (
            <section className="section contact">
                <h4 className="title">
                    {title}
                    <button className="edit-btn" onClick={() => stepClickHandler(editStepIdx)}>Edit</button>
                </h4>
                <p className="no-margin">
                    <span>Full name:</span> <span>{name}</span>
                </p>
                <p className="no-margin">
                    <span>Email:</span> <strong>{email}</strong>
                </p>
            </section>
        );
    };

    const renderGiftContacts = () => {
        const gifterDetails = renderContactDetails({
            title: 'Your details',
            name: `${getGifterValue('gifter_first_name')} ${getGifterValue('gifter_last_name')}`,
            email: getGifterValue('gifter_email'),
            editStepIdx: GIFTER_DETAILS_STEP_INDEX
        });

        const receiverDetails = renderContactDetails({
            title: 'Gift recipient',
            name: `${getGiftReceiverValue('receiver_first_name')} ${getGiftReceiverValue('receiver_last_name')}`,
            email: getGiftReceiverValue('receiver_email'),
            editStepIdx: RECIPIENT_DETAILS_STEP_INDEX
        });

        return (
            <>
                {gifterDetails}
                {receiverDetails}
            </>
        );
    };

    const renderCreateAccountForm = () => (
        <form className="create-account-form" onSubmit={submitForm}>
            <div className="section">
                <div className="form-group">
                    {CREATE_ACCOUNT_FORM_FIELDS.map((FIELD, index) => {
                        const { name: fieldName, label, validator, type, placeholder, isRequired, inputType } = FIELD;
                        const value = getFieldValue(fieldName);
                        const touched = getFieldTouched(fieldName);

                        const validationData = touched ? validateField(fieldName, value, validator) : createValidationDataObject();

                        const fieldData = {
                            index,
                            key: fieldName,
                            id: fieldName,
                            name: fieldName,
                            label,
                            type,
                            placeholder,
                            value,
                            onChangeHandler: (event) => {
                                setFieldValue(fieldName, event.target.value);
                                touchedField(fieldName);
                            },
                            onBlurHandler: () => touchedField(fieldName),
                            isRequired,
                            error: validationData.valid ? '' : validationData.message,
                            helper: helperMessage && helperMessage[fieldName]
                        };

                        switch (type) {
                            case 'checkbox':
                                return <CheckBox key={fieldName} inputType={inputType} label={label} value={value} onChangeHandler={(event) => {
                                    setFieldValue(fieldName, event.target.checked);
                                    touchedField(fieldName);
                                }
                                }/>;
                            case 'date':
                                return <DatePicker { ...fieldData } displayDateFormat={displayDateOfBirthFormat} disableFutureDates disableToday />;
                            default:
                                return <Input { ...fieldData } />;
                        }
                    })}
                </div>
                <RecaptchaPolicy />
            </div>
            {status && status.error && status.error.message && <div className="error-message">{status.error.message}</div>}
        </form>
    );

    const renderErrorMessage = () => {
        const { success, message } = paymentStatus || {};

        if (paymentStatus && !success) {
            return (
                <div className="error-banner || block || section">
                    <div className="constrain-width large no-pad">
                        <div className="inner">
                            <div className="constrain-width medium no-pad">
                                <Icon name="close" title="Error" />
                                <h4 className="title center">
                                    Something went wrong…<br />The payment couldn&apos;t be processed.
                                </h4>
                                <div className="error-message-body">
                                    The gateway was not able to process the payment for the following reason:<br/>{message}
                                </div>
                                <a
                                    className="error-message-action-url"
                                    href="/get-in-touch"
                                    target="_blank"
                                >If errors still persist, please contact us</a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    const renderCreateAccountSection = () => (
        <>
            <div className="constrain-width medium no-pad">
                <section className="section">
                    <h4 className="title">Create Membership Account</h4>
                    <p className="subtitle">With your membership account you’ll have all the details of your membership at your fingertips. You can update your contact information, see your visits and renew.</p>

                    {renderCreateAccountForm()}
                </section>
            </div>
            <hr />
        </>
    );

    const renderRegisterError = (status) => {
        if (status && status.error) {
            if (needResetPassword) {
                return (
                    <p>An account already exists for this email, please reset your password <Link to={FRONTEND_ROUTES.PORTAL_FORGOT_PASSWORD}>here</Link> and login.</p>
                );
            }

            return (
                <div className="portal-credential-board">
                    <p>{status.error.message}</p>
                    {needLoginWithoutRedirect && <LoginForm />}
                </div>
            );
        }

        return null;
    };

    const checkAccountMatch = () => {
        if (redeemDiscountStatus === 'redeemed' && extraDiscount && extraDiscount.ga) {
            const email = isAuth && user ? user.email : primaryContact.email.value;
            if (email !== extraDiscount.ga.account) {
                dispatch(setExtraDiscountAction('ga'));
                setRedeemDiscountStatus({ error: 'Please re-enter your code' });
                setPromoCodeInputValue('');

                if (!isAuth) {
                    setHelperMessage({ email: 'This email does not match the one associated with the day pass code, so the discount is no longer valid.' });
                }
            }
        }
    };

    useEffect(() => {
        // Ensure we have fetched the user before going to the next step, otherwise it will jump back to this step
        if (status && status.submitSuccess && isAuth) stepClickHandler(currentStepIndex + 1);
    }, [status, isAuth]);

    useEffect(() => {
        checkAccountMatch();
    }, [extraDiscount, primaryContact, isAuth]);

    useEffect(() => {
        // Push current cart to data layer
        fireCartEvent(EVENT_VIEW_CART, productsSelected, costsAfterDiscount, renewPassId, isGifting);
        autoFillPrimaryContactInfo();
        // Remove payment result query param here instead of during cleanup, otherwise it interferes with other navigation
        searchParams.delete('result');
        setSearchParams(searchParams, { replace: true });

        return () => {
            // remove the payment status message when leaving the page
            dispatch(clearPaymentStatusAction());
        };
    }, []);

    return (
        <>
            {renderErrorMessage()}
            <div className="inner">
                <div className="constrain-width medium no-pad || review-order">
                    {isGifting && renderGiftContacts()}
                    {canRedeemDaypassDiscount(isGifting) &&
                        <GaDiscountRedeem
                            isCreateAccountFormSubmmiting={isSubmitting}
                            promoCodeInputValue={promoCodeInputValue}
                            setPromoCodeInputValue={setPromoCodeInputValue}
                            setRedeemDiscountStatus={setRedeemDiscountStatus}
                            redeemDiscountStatus={redeemDiscountStatus}
                            setCreateAccountFormFieldValue={setFieldValue}
                            setHelperMessage={setHelperMessage}
                        />}
                    <OrderPricing
                        totalCosts={totalCosts}
                        saveCosts={saveCosts}
                        costsAfterDiscount={costsAfterDiscount}
                    />
                </div>
                <hr />
                {needsCreateAccount && renderCreateAccountSection()}
                <section className="section button-group">
                    <button
                        className="button"
                        onClick={() => stepClickHandler(currentStepIndex - 1)}
                    >Back</button>
                    <button
                        className="button primary"
                        onClick={submitForm}
                        disabled={isSubmitting || redeemDiscountStatus === 'pending' || checkIsFormValueInvalid()}
                    >
                        {isSubmitting ? <Loader type="small" /> : 'Next'}
                    </button>
                </section>
            </div>
            <Modal isVisible={modalVisible} onCloseHandler={resetStatus}>
                {renderRegisterError(status)}
                {!needLoginWithoutRedirect && (
                    <div className="button-group">
                        <button className="button" onClick={resetStatus}>
                            Close
                        </button>
                        <button className="button primary" onClick={() => navigate(FRONTEND_ROUTES.PORTAL_LOGIN)}>
                            Go to Login
                        </button>
                    </div>
                )}
            </Modal>
        </>
    );
};

Review.propTypes = {
    totalCosts: PropTypes.string.isRequired,
    saveCosts: PropTypes.string.isRequired,
    costsAfterDiscount: PropTypes.string.isRequired,
    stepClickHandler: PropTypes.func.isRequired,
    isGifting: PropTypes.bool
};

export default Review;
